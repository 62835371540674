import React from 'react'
import Layout from '../layouts/mainlayout.js'


export default function index() {
  return (
    <Layout>
      <div className="aboutHeader">
        <p className="aboutHeaderContainer">This is an About page</p>
      </div>
    </Layout>
  )
}